import React from 'react';

export default function SocialCard(props) {
  return (
    <div
      className='col-md-6'
      style={{
        border: 'none',
        padding: '5px',
        borderRadius: '20px',
        // backgroundColor: 'pink',
      }}
    >
      <div
        style={{
          border: '3px solid white',
          padding: '10px',
          borderRadius: '20px',
          backgroundColor: 'orange',
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
