import React from 'react';
import Twitter from './../Media/Navigation/Twitter.png';
import Github from './../Media/Navigation/Github.png';
import Linkedin from './../Media/Navigation/Linkedin.png';
import Instapaper from './../Media/Navigation/Instapaper.png';

export default function Footer() {
  return (
    <div
      style={{
        backgroundColor: 'rgb(255, 137, 13)',
        padding: '50px 0px',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <p>
          <a href='/'> alvie.org</a>
        </p>
        <p>Built by Alvie Stoddard. © 2022</p>
        <p>
          <a
            href='https://twitter.com/alvierado'
            target='_blank'
            rel='noreferrer'
          >
            <img
              src={Twitter}
              width={20}
              alt='alvierado on Twitter'
              target='_blank'
              rel='noreferrer'
            />
          </a>
          {' - '}

          <a href='https://github.com/alvst'>
            <img
              src={Github}
              width={20}
              alt='alvst on Github'
              target='_blank'
              rel='noreferrer'
            />
          </a>
          {' - '}
          <a href='https://www.linkedin.com/in/alvst/'>
            <img
              src={Linkedin}
              width={20}
              alt='alvst on LinkedIn'
              target='_blank'
              rel='noreferrer'
            />
          </a>
          {' - '}
          <a href='https://www.instapaper.com/p/alvst'>
            <img
              src={Instapaper}
              width={20}
              alt='alvst on Instapaper'
              target='_blank'
              rel='noreferrer'
            />
          </a>
        </p>
      </div>
    </div>
  );
}
