import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavBar() {
  return (
    <div className='d-none d-lg-block col-sm-2'>
      <div style={{ margin: '10px' }}>
        <div
          style={{
            border: '3px solid #f5f5f5',
            borderRadius: '20px',
            padding: '10px',
          }}
        >
          <ul className='nav flex-column d-none d-lg-block'>
            <li>
              <NavLink
                to='/'
                exact
                activeClassName='active'
                className='nav-link'
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/projects'
                activeClassName='active'
                className='nav-link'
              >
                Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/books'
                activeClassName='active'
                className='nav-link'
              >
                Books
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/podcasts'
                activeClassName='active'
                className='nav-link'
              >
                Podcasts
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/resume'
                activeClassName='active'
                className='nav-link'
              >
                Resume
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
