import React from 'react';
import { NavLink } from 'react-router-dom';

function Header() {
  return (
    <div>
      <div
        style={{
          backgroundColor: 'rgb(255, 137, 13)',
          paddingBottom: '10px',
        }}
      >
        <div
          style={{
            backgroundColor: 'rgb(255, 137, 13)',
            padding: '50px 0px 20px 0px',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <h1>Alvie Stoddard</h1>
            <h3>Technologist, Sports Enthusiast, &amp; Entrepreneur</h3>
          </div>
        </div>
        <div className='d-none d-lg-block  d-xl-block'>
          <hr />
        </div>
      </div>
      <div
        className='d-sm-block d-lg-none'
        style={{ textAlign: 'center', padding: '20px 0px 5px 0px' }}
      >
        <p>
          <NavLink to='/' exact activeClassName='active' className='nav-mobile'>
            Home
          </NavLink>
          <NavLink
            to='/projects'
            exact
            activeClassName='active'
            className='nav-mobile'
          >
            Projects
          </NavLink>
          <NavLink
            to='/books'
            exact
            activeClassName='active'
            className='nav-mobile'
          >
            Books
          </NavLink>
        </p>
        <p style={{ marginTop: '25px' }}>
          <NavLink
            to='/podcasts'
            exact
            activeClassName='active'
            className='nav-mobile'
          >
            Podcasts
          </NavLink>
          <NavLink
            to='/resume'
            exact
            activeClassName='active'
            className='nav-mobile'
          >
            Resume
          </NavLink>
        </p>
        <p>
          <img
            src='/static/media/Twitter.aa59929c.png'
            width={30}
            alt='Follow me on Twitter @alvierado'
            className='nav-mobile-icons'
          />
          <img
            src='/static/media/Linkedin.c3d8a710.png'
            width={30}
            alt='Follow me on Twitter @alvierado'
            className='nav-mobile-icons'
          />
          <img
            src='/static/media/Github.b1b7d0ed.png'
            width={30}
            alt='Follow me on Twitter @alvierado'
            className='nav-mobile-icons'
          />
          <img
            src='/static/media/Instapaper.588107c2.png'
            width={30}
            alt='Follow me on Twitter @alvierado'
            className='nav-mobile-icons'
          />
        </p>
        <div className='d-none d-lg-block  d-xl-block'>
          <hr style={{ color: 'orange' }} />
        </div>
      </div>
    </div>
  );
}

export default Header;
