import React from 'react';

export default function Resume() {
  return (
    <div className='col-md' style={{ marginTop: '15px' }}>
      <h1 style={{ textAlign: 'center' }}>Alvorado (Alvie) Stoddard II</h1>
      <h6 style={{ textAlign: 'center' }}>
        <a href='linkedin.com/in/alvst'>LinkedIn</a> |{' '}
        <a href='GitHub.com/alvst'>GitHub</a>
      </h6>
      <h3>Work Experience </h3>
      <h5>EY - Technology Risk Staff II Consultant</h5>
      <h6>
        San Francisco, CA | Technology Risk Consultant in San Francisco Office |
        Fall 2021 - Present
      </h6>
      <ul>
        <li>
          Support clients in understanding the risks of their current technology
          systems. Assist in managing offshore teams making sure tasks are
          assigned and completed in a timely manner.
        </li>
        <li>
          Work with a team of consultants focused on protecting the economic
          value of our clients -- assisting them in the evaluation and
          management of IT risks: Cybersecurity, Employee Access, Managing
          System Change, and IT Operations.
        </li>
        <li>Top 5% Utilization in West Region.</li>
      </ul>
      <h5>
        2021 TRANSFR VR – VC-funded company delivering VR educational
        experiences for middle skilled jobs.
      </h5>
      <h6>
        Wellesley, MA | Student Consultant (through Babson College) | Summer
        2020
      </h6>
      <ul>
        <li>
          Consulted with the CEO and Director of Finance to research emerging
          middle-skilled jobs, growing educational institutions, and emerging
          trends within the Automotive, Aerospace, and Construction industries.
        </li>
        <li>
          Presented findings about Future of Work (FoW) in relevant sectors and
          how TRANSFR’s technology could be applicable.
        </li>
      </ul>
      <h5>
        Applause Corporation – VC-funded company that runs testing and usability
        research platforms.
      </h5>
      <h6>
        Framingham, MA | Sales Support and Community Management | Summer Intern
        Summer 2019
      </h6>
      <ul>
        <li>
          Supported members of the sales staff, operations team, and tester
          community, to resolve technical problems with the platform through RT
          (Request Tracker) and ZenDesk tickets.
        </li>
        <li>
          Assisted in creating, retaining, and building Applause’s tester
          community.
        </li>
        <li>
          Activities included: determining high performing testers, automating
          technological problems, and building new platforms.
        </li>
      </ul>
      <h5>
        Humanyze – VC-funded company delivering people analytics solutions.
      </h5>
      <h6>
        San Francisco, CA | Student Consultant (through Babson College) | Spring
        2019
      </h6>
      <ul>
        <li>
          Researched potential leads and streamlined company vision, purpose and
          value, proposition.
        </li>
      </ul>
      <h5>
        The 1854 Cycling Company – Premium bicycle and cycling apparel company.
      </h5>
      <h6>Framingham, MA | Summer Intern | Summer 2018</h6>
      <ul>
        <li>
          Worked directly with the CEO and Founder to evaluate and create
          go-to-market strategy for new categories.
        </li>
      </ul>
      <hr />
      <h3>Education</h3>
      <h5>Babson College</h5>
      <ul>
        <li>Bachelor of Science; Magna Cum Laude</li>
        <li>
          Two Concentrations: Information Technology Management (ITM) and
          Technology, Entrepreneurship, &amp; Design (TED).
        </li>
        <li>
          Academic Award: Information Systems Award - Awarded to the senior with
          the highest cumulative average in IT courses.
        </li>
        <li>
          Study Away: Completed courses in Technology and Entrepreneurship at
          Babson San Francisco in Spring 2019; attended company visits to Tesla,
          PayPal, Fitbit, Dolby, and more. Also attended Paris Startup (5/2018)
          and London Stage (1/2020).
        </li>
        <li>
          Relevant Courses: Web Technologies, Problem Solving in Python &amp;
          Software Design, Information Technology, Operations, Silicon Tech
          Ventures, Social Media Strategy, Consulting in Technology
          Entrepreneurship.
        </li>
      </ul>
      <h5>
        iSchool Inclusion Institute (i3) – University of Pittsburgh – Computing
        &amp; Information, i3 Scholar Summer 2018
      </h5>
      <ul>
        <li>
          Research and leadership development program preparing underrepresented
          students for careers in information science and computing. Selected
          from a nationwide search (12.5% acceptance rate).
        </li>
        <li>
          Finished 50+ academic and professional development workshops;
          instructional modules in research design and programming; presented
          research proposal for a 20-month-long project.
        </li>
        <li>
          Currently finishing a 20-month-long team-based research project
          investigating Virtual Reality and Ethics.
        </li>
        <li>
          Published and presented research at peer-reviewed, international
          conference for information and computing (iConference 2019, Washington
          D.C.).
        </li>
        <li>Fully-funded research fellow.</li>
      </ul>
      <hr />
      <h3>Awards, Leadership, Clubs, Organizations, and Activities</h3>
      <ul>
        <li>
          Babson CODE Advisor (2021-Present) &amp; President (2020-2021) &amp;
          Vice President (2019-2020)
        </li>
        <li>Capital One Kubernetes Hackathon 2nd Place ($2,500)</li>
        <li>
          2021 Winner of Northeast Venture Capital Investment Competition
          Entrepreneur’s Choice Award
        </li>
        <li>Babson Summer Catalyst (Summer 2021) - XHighlight</li>
        <li>Babson Leadership Academy</li>
        <li>Babson Black Student Union (BSU)</li>
        <li>Selected for Global Venture Catalyst Program</li>
      </ul>
      <hr />
      <h3>Other Interests</h3>
      <ul>
        <li>
          Wellesley High School Varsity and Junior Varsity Basketball Player
          (2014-2017).
        </li>
        <li>
          Assistant Coach and Scout for St. Joseph’s Prep Varsity Basketball
          Winter 2017-2018.
        </li>
        <li>
          Enjoys podcasts, articles, and books about Technology, Business,
          Investing, and Strategy.
        </li>
      </ul>
    </div>
  );
}
