import React from 'react';

export default function NoMatch() {
  return (
    <div className='col-md' style={{ minHeight: ' 65vh', marginTop: '15px' }}>
      <h1 style={{ textAlign: 'center' }}>
        {' '}
        No Match. Proceed to a different Page
      </h1>
      <div className='row'>
        <div className='col' style={{ textAlign: 'center' }}>
          <a href='/' className='no-match nav-link'>
            About Me
          </a>
        </div>
        <div className='col' style={{ textAlign: 'center' }}>
          <a href='/projects' className='no-match nav-link'>
            Projects
          </a>
        </div>

        <div className='col' style={{ textAlign: 'center' }}>
          <a href='/books' className='no-match nav-link'>
            Books
          </a>
        </div>
        <div className='col' style={{ textAlign: 'center' }}>
          <a href='/resume' className='no-match nav-link'>
            Resume
          </a>
        </div>
      </div>
    </div>
  );
}
