import React from 'react';

export default function Books() {
  return (
    <div className='col-md' style={{ marginTop: '15px' }}>
      <h3>Favorite Books I've Read</h3>
      <div className='row'>
        <IndividualBook
          book={{
            name: 'The Everything Store',
            alt: 'The Everything Store',
            src: 'https://images.gr-assets.com/books/1409111736l/22913113.jpg',
            favorite: true,
          }}
        />
        <IndividualBook
          book={{
            name: 'Hatching Twitter',
            alt: 'Hatching Twitter',
            src: 'https://images-na.ssl-images-amazon.com/images/I/81KhtwLf5oL.jpg',
            favorite: true,
          }}
        />
        <IndividualBook
          book={{
            name: 'To Pixar and Beyond',
            alt: 'To Pixar and Beyond',
            src: 'https://m.media-amazon.com/images/I/51a16VhS5JL.jpg',
            favorite: true,
          }}
        />

        <h3>Books I'm Currently Reding</h3>
        <div className='row'>
          <IndividualBook
            book={{
              name: 'Sweating Bullets',
              alt: 'Sweating Bullets',
              src: 'https://images-na.ssl-images-amazon.com/images/I/41C13m5ZjWL._SX323_BO1,204,203,200_.jpg',
              favorite: true,
            }}
          />
        </div>
        <div
          style={{
            backgroundColor: '#ff890d',
            border: '3px solid #ff890d',
            padding: '10px',
            borderRadius: '40px',
            textAlign: 'center',
            marginBottom: '30px',
            opacity: '90%',
          }}
        >
          <h1>Articles</h1>
          <h4>
            While I've read a lot of books, most of my weekly reading is
            recently published articles.
          </h4>
          <p>
            Check out my <a href='https://instapaper.com/alvst'>Instapaer</a> to
            learn see what I've been reading lately.
          </p>
        </div>
      </div>
    </div>
  );
}

function IndividualBook(props) {
  let { name, alt, src, favorite } = props.book;

  return (
    <div className='col-lg-3' style={{ margin: '10px' }}>
      <img
        src={src}
        alt={alt}
        className='img-fluid'
        style={{ margin: '10px', maxHeight: '400px' }}
      />
      <div className='row'>
        <div className='col-10'>
          <h6>
            <span style={{ marginRight: '0px' }}>{name}</span>
          </h6>
        </div>
        {favorite === true && (
          <div className='d-none d-lg-block col-2'>
            <div className='book-hover'>
              ★
              <div className='content'>
                <p>Denotes one of my favorite books.</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
