import React from 'react';

export default function Podcasts() {
  return (
    <div className='col-md' style={{ marginTop: '15px' }}>
      <h3>Technology</h3>
      <div className='row'>
        <div className='col-lg-4'>
          <div className='no-link centered' style={{ margin: '15px' }}>
            <a href='https://atp.fm'>
              <img
                src='https://pbs.twimg.com/profile_images/1397949971681456128/tbzG_q7m_400x400.jpg'
                alt='Accidental Tech Podcast'
                className='img-fluid'
              />
              <h4>Accidental Tech Podcast</h4>
            </a>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='no-link centered' style={{ margin: '15px' }}>
            <a href='https'>
              <img
                src='https://podlink.imgix.net/f25ec9a70fd8c94c8817669bc6633e43/poster.jpeg?width=448&auto=format'
                alt='Acquired'
                className='img-fluid'
              />
              <h4>Acquired</h4>
            </a>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='no-link centered' style={{ margin: '15px' }}>
            <a href='https://images.gr-assets.com/books/1409111736l/22913113.jpg'>
              <img
                src='https://daringfireball.net/thetalkshow/graphics/df-the-talk-show-album-art.png'
                alt='The Talk Show'
                className='img-fluid'
              />
              <h4>The Talk Show</h4>
            </a>
          </div>
        </div>
      </div>
      <h3>Tech &amp; Society</h3>
      <div className='row'>
        <div className='col-lg-4'>
          <div className='no-link centered' style={{ margin: '15px' }}>
            <a href='https://images.gr-assets.com/books/1409111736l/22913113.jpg'>
              <img
                src='https://podpage.imgix.net/https%3A%2F%2Fs3.us-west-1.amazonaws.com%2Fredwood-labs%2Fshowpage%2Fuploads%2Fimages%2F4640d0d6dd51449f97febd064850fd45.png?h=720&w=800&s=3e11b71b2b8607c7d866dafc67f6897a'
                alt='TechMeme Ride Home'
                className='img-fluid'
              />
              <h4>TechMeme Ride Home</h4>
            </a>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='no-link centered' style={{ margin: '15px' }}>
            <a href='https://images.gr-assets.com/books/1409111736l/22913113.jpg'>
              <img
                src='https://m.media-amazon.com/images/I/415pN2yfvSL.jpg'
                alt='All In'
                className='img-fluid'
              />
              <h4>All In</h4>
            </a>
          </div>
        </div>
      </div>
      <h3>Strategy</h3>
      <div className='row'>
        <div className='col-lg-4'>
          <div className='no-link centered' style={{ margin: '15px' }}>
            <a href='https://images.gr-assets.com/books/1409111736l/22913113.jpg'>
              <img
                src='https://stratechery.com/wp-content/uploads/2020/05/Stratechery-Podcast-Artwork.png'
                alt='Stratechery'
                className='img-fluid'
              />
              <h4>Stratechery</h4>
            </a>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='no-link centered' style={{ margin: '15px' }}>
            <a href='https://images.gr-assets.com/books/1409111736l/22913113.jpg'>
              <img
                src='https://i2.wp.com/dithering.fm/wp-content/uploads/2021/10/DitheringCoverart.jpg?w=1600&ssl=1'
                alt='Dithering'
                className='img-fluid'
              />
              <h4>Dithering</h4>
            </a>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='no-link centered' style={{ margin: '15px' }}>
            <a href='https://images.gr-assets.com/books/1409111736l/22913113.jpg'>
              <img
                src='https://podlink.imgix.net/d1f1d37ad853f1c2ba462c52537ba9ff/poster.jpeg?width=448&auto=format'
                alt='Exponent'
                className='img-fluid'
              />
              <h4>Exponent</h4>
            </a>
          </div>
        </div>
      </div>
      <h3>Other/Miscellaneous</h3>
      <div className='row'>
        <div className='col-lg-4'>
          <div className='no-link centered' style={{ margin: '15px' }}>
            <a href='https://images.gr-assets.com/books/1409111736l/22913113.jpg'>
              <img
                src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Hello_Internet_Logo.svg/1200px-Hello_Internet_Logo.svg.png'
                alt='Hello Internet'
                className='img-fluid'
              />
              <h4>Hello Internet</h4>
            </a>
          </div>
        </div>
        <div className='col-lg-4'>
          <div className='no-link centered' style={{ margin: '15px' }}>
            <a href='https://images.gr-assets.com/books/1409111736l/22913113.jpg'>
              <img
                src='https://relayfm.s3.amazonaws.com/uploads/broadcast/image_1x/17/cortex_artwork.png'
                alt='Cortex'
                className='img-fluid'
              />
              <h4>Cortex</h4>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
