import React from 'react';

import Twitter from './../Media/Navigation/Twitter.png';
import Github from './../Media/Navigation/Github.png';
import Linkedin from './../Media/Navigation/Linkedin.png';
import Instapaper from './../Media/Navigation/Instapaper.png';

export default function SideBar() {
  return (
    <div className='d-none d-lg-block col-sm-2' style={{ textAlign: 'center' }}>
      <ul className='nav flex-column'>
        <div>
          <li className='nav-item' style={{ padding: '10px' }}>
            <a
              href='https://twitter.com/alvierado'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={Twitter}
                width={30}
                alt='Follow me on Twitter @alvierado'
              />
            </a>
          </li>
          <li className='nav-item' style={{ padding: '10px' }}>
            <a
              href='https://github.com/alvst'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={Github} width={30} alt='Find me on Github @alvst' />
            </a>
          </li>
          <li className='nav-item' style={{ padding: '10px' }}>
            <a
              href='https://linkedin.com/in/alvst'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={Linkedin}
                width={30}
                alt='Check me out on Linkedin alvst'
              />
            </a>
          </li>
          <li className='nav-item' style={{ padding: '10px' }}>
            <a
              href='https://instapaper.com/p/alvst'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={Instapaper}
                width={30}
                alt="See what I've been reading on Instapaper"
              />
            </a>
          </li>
        </div>
      </ul>
    </div>
  );
}
