import React from 'react';
import XHighlight from './../Media/XHighlight Logo.png';
import KoachView from './../Media/KoachView Logo with Background.png';
import i3 from './../Media/i3-group-photo.jpg';
import CODE from './../Media/Projects/CODE Logo Black.png';
import GithubEmail from './../Media/Projects/Github Email.png';
import GithubMusic from './../Media/Projects/Github Music.png';
import Letterboxd from './../Media/Projects/Letterboxd.png';
import MLAGithub from './../Media/Projects/MLA Github.png';
import NPM from './../Media/Projects/NPM Video Preview.png';
import GSE from './../Media/Projects/GSE.png';
import ProjectJson from './AllProjects.json';

export default function Projects() {
  return (
    <div className='col-md' style={{ marginTop: '15px' }}>
      <div className='no-link'>
        <h1>Featured Projects</h1>
        <div>
          <a href='https://www.xhighlight.com' className='red-link'>
            <h3 style={{ textAlign: 'center' }}>XHighlight</h3>
          </a>
          <a href='https://www.xhighlight.com' className='red-link'>
            <h5 style={{ textAlign: 'center' }}>
              The Fastest and Easiest Way to make your Sports Highlight Videos
            </h5>
          </a>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <img src={XHighlight} alt='XHighlight Logo' className='img-fluid' />
          </div>
        </div>
        <div style={{ marginTop: '30px' }}>
          <a href='https://koachview.com' className='red-link'>
            <h3 style={{ textAlign: 'center' }}>KoachView</h3>
          </a>
          <a href='https://koachview.com' className='red-link'>
            <h5 style={{ textAlign: 'center' }}>
              Run your Sports Film Sessions Like a Pro
            </h5>
          </a>
          <div style={{ textAlign: 'center', marginTop: '5px' }}>
            <img src={KoachView} alt='KoachView Logo' className='img-fluid' />
          </div>
        </div>
        <hr />
      </div>
      <h1>Previous Projects</h1>
      <div className='row no-link'>
        <div className='col-md-6'>
          <a
            href='http://www.i3-inclusion.org/news/but-its-not-real-is-it-exploring-virtual-reality-ethics-and-diversity/'
            className='green-link'
          >
            <h3 style={{ textAlign: 'center' }}>i3: Is it Real Or Not?</h3>
          </a>
          <div className='min-img-height'>
            <img src={i3} alt='My i3 team' className='img-fluid' />
          </div>
          <p style={{ marginTop: '20px' }}>
            After my freshman year, I was accepted into the{' '}
            <a href='http://www.i3-inclusion.org/' className='green-link'>
              iSchool Inclusion Institute (i3)
            </a>
            , a program for students interested in technology, research, and
            graduate school. As a part of my i3 experience, I worked with 4
            other undergraduate students on an academic research project, "But
            it's not real is it? Exploring Virtual Reality, Ethics, and
            Diversity". We presented our findings to PhD's, Faculty, and PhD
            candidates in Maryland at iConference 2019 and hope to submit our
            two follow up papers at future conferences.
          </p>
        </div>
        <div className='col-md-6 no-link'>
          <div>
            <a href='https://www.transfrvr.com' className='rainbow-link'>
              <h3 style={{ textAlign: 'center' }}>Transfr VR</h3>
            </a>

            <div
              style={{ textAlign: 'center', marginTop: '20px' }}
              className='min-img-height'
            >
              <img
                src='https://mms.businesswire.com/media/20201119005285/en/840201/5/tvr_logo_colors.jpg'
                alt='Transfrvr Logo'
                className='img-fluid'
              />
            </div>
            <p style={{ marginTop: '20px' }} />
            <p>
              During Summer 2020, myself and 2 other students completed Research
              for TransfrVR, on the post-COVID job market for I automotive,
              aerospace, and construction companies in the US. The US car
              industry is one I am extremely interested in due to the major
              changes likely coming to the industry. From electrification, to
              Autonomous Vehicles, and more, new entrants and manufacturing
              processes are poised to shift the job industry.
            </p>
          </div>
        </div>
      </div>
      <hr />
      <h1>Other Github Projects</h1>
      <div className='row'>
        {ProjectJson.miniProjects.map((project, index) => (
          <MiniProjects project={project} index={index} key={index} />
        ))}
      </div>
    </div>
  );
}

function MiniProjects(props) {
  let index = props.index;
  props = props.project;
  let projectLogo = [
    NPM,
    GithubMusic,
    MLAGithub,
    Letterboxd,
    GithubEmail,
    CODE,
    GSE,
  ];
  return (
    <div className='col-md-4 no-link'>
      <div className='main-container'>
        <img
          src={projectLogo[index]}
          alt='React Video Upload Preview'
          className='project-image img-fluid'
        />
        <div className='overlay no-link'>
          <div style={{ margin: '10px' }}>
            <a href={props.link}>
              <h5>{props.name}</h5>
            </a>
            <p>{props.description}</p>
            <p>
              <a href={props.link}>View Project</a>
            </p>
          </div>
        </div>
      </div>
      <a href={props.link}>
        <h4>{props.name}</h4>
      </a>
    </div>
  );
}
