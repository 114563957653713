import { Routes, Route, Navigate } from 'react-router-dom';
import './Styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Navigation
import Header from './Components/Header';
import NavBar from './Navigation/NavBar';
import SideBar from './Components/SideBar';
import Footer from './Navigation/Footer';

// Site Pages
import Home from './Pages/Home';
import Podcasts from './Pages/Podcasts';
import Resume from './Pages/Resume';
import Resume2 from './Pages/Resume2';
import Projects from './Pages/Projects';
import Books from './Pages/Books';
import NoMatch from './Pages/NoMatch';

function App() {
  return (
    <div>
      <Header />
      <div
        style={{
          backgroundImage:
            // 'linear-gradient(176deg,#ff890d 40%,#ff8a0dbe calc(40% + 2px) calc(60%),#f4f4f4 calc(60% + 2px)',
            'linear-gradient(176deg,#ff890d 30%,#ff8a0dbe calc(30% + 2px) calc(60%),#f4f4f4 calc(60% + 2px)',
          minHeight: '100vh',
        }}
      >
        <div className='container-fluid'>
          <div className='row'>
            <NavBar />
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route path='/podcasts' element={<Podcasts />} />
              <Route path='/resume' element={<Resume />} />
              {/* <Route path='/resume2' element={<Resume2 />} /> */}
              <Route path='/projects' element={<Projects />} />
              <Route path='/books' element={<Books />} />

              <Route path='/404' element={<NoMatch />} />
              <Route path='*' element={<Navigate to='/404' />} />
            </Routes>
            <SideBar />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
