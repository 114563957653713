import React from 'react';
// import Profile from './../Media/Profile Circle.png';
import Profile from './../Media/Profile Transparent.png';
import './../Styles.css';
import SocialCard from './Components/SocialCard';

export default function Home() {
  return (
    <div className='col-md' style={{ marginTop: '15px' }}>
      <div className='d-sm-block d-md-none' style={{ textAlign: 'center' }}>
        <h3>Hi, I am Alvie Stoddard.</h3>
      </div>
      <div style={{ textAlign: 'center' }}>
        <img
          src={Profile}
          alt=''
          className='img-fluid img-float'
          width='300px'
          style={{ margin: '10px' }}
        />
      </div>
      <p>
        <span className='d-md-inline-block d-none'>
          Hi, I am Alvie Stoddard.
        </span>{' '}
        I enjoy programming and making websites. I am an aspiring entrepreneur
        who is currently located in the Bay Area. I also enjoy everything tech
        related. I am a self taught programmer who has always had a passion for
        technology. I mainly use React for frontend and Python for backend and
        algorithms. I have the most experience hosting applications on Firebase
        but have also worked with AWS and Heroku.
      </p>
      <p>
        I enjoy reading articles and listening to podcasts on technology,
        startups, and technology strategy. I also keep up with the daily news
        and developments within the tech and VC ecosystem.
      </p>
      <p>
        I've also played basketball my entire life. I have combined my two
        passions, basketball and technology to create{' '}
        <a href='https://xhighlight.com' className='red-link'>
          XHighlight
        </a>
        , the fastest and easiest way to make sports highlight videos. I have
        also created{' '}
        <a href='https://koachview.com' className='red-link'>
          KoachView
        </a>
        , a web application for holding the most effective film sessions.
      </p>
      <p>
        In addition to basketball and technology, I enjoy weight-lifting,
        programming, and reading & listening to books. My favorite books to read
        are non-fiction books, specifically about technology, and even more
        specifically, biographies and story books about how companies came to
        be. I also read and listen to many articles every week about what's
        going on with startups, check out my{' '}
        <a href='https://intsapaper.com/u/alvst'>Instpaper</a> to see what I've
        enjoyed recently. Each week, I listen to numerous of podcasts. Some of
        my favorites can be found <a href='/podcasts'>here</a>. Similarly, my
        favorite books can be found <a href='/books'>here</a>.
      </p>
      <h1>Find Me Around the Internet</h1>
      <div className='row'>
        <SocialCard>
          <a href='https://twitter.com/alvierado' className='no-link'>
            <h3>Twitter</h3>
          </a>
          <p>
            Follow me on{' '}
            <a href='https://twitter.com/alvierado'>Twitter, @alvierado</a> to
            hear my thoughts on Basketball, Tesla, Apple, Crypto, and Tech
            Strategy.
          </p>
        </SocialCard>
        <SocialCard>
          <a href='https://linkedin.com/in/alvst' className='no-link'>
            <h3>LinkedIn</h3>
          </a>
          <p>
            Learn more about my previous projects and work experience on{' '}
            <a href='https://www.linkedin.com/in/alvst/'>LinkedIn</a>.
          </p>
        </SocialCard>
        <SocialCard>
          <a href='https://instapaper.com/alvst' className='no-link'>
            <h3>Instapaper</h3>
          </a>
          <p>
            See the best articles I've read recently on{' '}
            <a href='https://instapaper.com/alvst'>Instappaer</a>.
          </p>
        </SocialCard>
        <SocialCard>
          <a href='https://clubhouse.com/@alvie' className='no-link'>
            <h3>Clubhouse</h3>
          </a>

          <p>
            Find me on{' '}
            <a href='https://clubhouse.com/@alvie'>Clubhouse @alvie</a> talking
            mostly about Tesla.
          </p>
        </SocialCard>

        <SocialCard>
          <a href='https://github.com/alvst' className='no-link'>
            <h3>Github</h3>
          </a>
          <p>
            Check out my{' '}
            <a href='https://github.com/alvst'> programming projects</a>.
          </p>
        </SocialCard>
      </div>
      <a rel='me' href='https://mastodon.social/@alvie'></a>
    </div>
  );
}
